var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("pain-person-question-dialog", {
        ref: "discardPainDialog",
        attrs: {
          heading: _vm.$t("painPerson.discardDialog.heading", {
            0: _vm.areaToBeRemovedText
          }),
          subheading: _vm.$t("painPerson.discardDialog.subheading", {
            0: _vm.areaToBeRemovedText
          }),
          confirmText: _vm.$t("buttons.yes"),
          declineText: _vm.$t("buttons.no"),
          image: _vm.areaToRemoveImage,
          outlineImage: ""
        }
      }),
      _vm.showPainQuestionPurposeDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                width: _vm.$vuetify.breakpoint.smAndDown ? "85vw" : "75vw"
              },
              model: {
                value: _vm.showPainQuestionPurposeDialog,
                callback: function($$v) {
                  _vm.showPainQuestionPurposeDialog = $$v
                },
                expression: "showPainQuestionPurposeDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/images/wellness/pain-reason.png")
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-button",
                      attrs: {
                        icon: "",
                        large: _vm.$vuetify.breakpoint.mdAndUp
                      },
                      on: {
                        click: function($event) {
                          _vm.showPainQuestionPurposeDialog = false
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "black" } }, [
                        _vm._v("mdi-close")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.disabled && !_vm.hideGenderChange
        ? _c(
            "v-row",
            { attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary lighten-1", "x-small": "" },
                  on: { click: _vm.changeGender }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("buttons.genderSwitch", {
                        "0": _vm.$t(_vm.genderText)
                      })
                    )
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "max-width": _vm.analyticsMode ? 120 : "",
                "content-class": "text-center",
                "nudge-bottom": _vm.analyticsMode ? 100 : 0,
                disabled: _vm.disabled && !_vm.analyticsMode,
                "open-on-click": _vm.analyticsMode,
                "z-index": "0"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "svg",
                        _vm._g(
                          _vm._b(
                            {
                              staticStyle: {
                                cursor: "default",
                                outline: "none !important"
                              },
                              attrs: {
                                height: "100%",
                                width: "400",
                                viewBox: "0 100 230 270",
                                preserve: ""
                              }
                            },
                            "svg",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "text",
                            {
                              attrs: {
                                fill: "grey",
                                "font-size": "10px",
                                x: "41",
                                y: "117"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("painPerson.front")) + " "
                              )
                            ]
                          ),
                          _c(
                            "text",
                            {
                              attrs: {
                                fill: "grey",
                                "font-size": "10px",
                                x: "166",
                                y: "117"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("painPerson.back")) + " "
                              )
                            ]
                          ),
                          _vm._l(_vm.regionsToPlot, function(ref, index) {
                            var name = ref[0]
                            var path = ref[1]
                            return _c("path", {
                              key: index,
                              staticClass: "body-area",
                              style:
                                _vm.disabled || _vm.isAreaDisabled(name)
                                  ? ""
                                  : "cursor: pointer",
                              attrs: {
                                d: path,
                                id: name,
                                fill: _vm.analyticsMode
                                  ? _vm.getAnalyticsPainColor(name)
                                  : _vm.regionColour[name]
                              },
                              on: {
                                mouseover: function($event) {
                                  return _vm.onHover(name)
                                },
                                mouseleave: function($event) {
                                  return _vm.onLeave(name)
                                },
                                click: function($event) {
                                  return _vm.onClick(name)
                                },
                                touchstart: function($event) {
                                  _vm.touchEvent = true
                                }
                              }
                            })
                          })
                        ],
                        2
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.bodyPartToolTip) }
              })
            ]
          )
        ],
        1
      ),
      !_vm.disabled && !_vm.hideChips
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            _vm._l(_vm.selectedAreas, function(area) {
              return _c(
                "v-chip",
                {
                  key: area,
                  staticClass: "text-body-1 ma-2",
                  attrs: {
                    close: "",
                    outlined: "",
                    "append-icon": "close",
                    "close-icon": "close"
                  },
                  on: {
                    "click:close": function($event) {
                      return _vm.confirmRemovePainArea(area)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t(_vm.svgRegions[area].name)))]
              )
            }),
            1
          )
        : _vm._e(),
      !_vm.disabled && !_vm.hideChips && _vm.showWhyWeAskButton
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-2 text-none",
                  attrs: {
                    color: "green",
                    outlined: "",
                    rounded: "",
                    "x-small": ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.openWhyWeAskDialog()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.whyWeAsk")) + " ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }