<template>
  <div>
    <pain-person-question-dialog
      ref="discardPainDialog"
      :heading="
        $t('painPerson.discardDialog.heading', {
          0: areaToBeRemovedText
        })
      "
      :subheading="
        $t('painPerson.discardDialog.subheading', {
          0: areaToBeRemovedText
        })
      "
      :confirmText="$t('buttons.yes')"
      :declineText="$t('buttons.no')"
      :image="areaToRemoveImage"
      outlineImage
    />
    <v-dialog
      v-if="showPainQuestionPurposeDialog"
      v-model="showPainQuestionPurposeDialog"
      :width="$vuetify.breakpoint.smAndDown ? '85vw' : '75vw'"
    >
      <v-card>
        <v-img :src="require('@/assets/images/wellness/pain-reason.png')" />
        <v-btn
          class="close-button"
          icon
          :large="$vuetify.breakpoint.mdAndUp"
          @click="showPainQuestionPurposeDialog = false"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-row justify="end" v-if="!disabled && !hideGenderChange">
      <v-btn color="primary lighten-1" x-small @click="changeGender">{{
        $t("buttons.genderSwitch", { "0": $t(genderText) })
      }}</v-btn>
    </v-row>
    <v-row justify="center">
      <v-tooltip
        top
        :max-width="analyticsMode ? 120 : ''"
        content-class="text-center"
        :nudge-bottom="analyticsMode ? 100 : 0"
        :disabled="disabled && !analyticsMode"
        :open-on-click="analyticsMode"
        z-index="0"
      >
        <template v-slot:activator="{ on, attrs }">
          <svg
            height="100%"
            width="400"
            viewBox="0 100 230 270"
            v-bind="attrs"
            v-on="on"
            preserve
            style="cursor: default; outline: none !important"
          >
            <text fill="grey" font-size="10px" x="41" y="117">
              {{ $t("painPerson.front") }}
            </text>
            <text fill="grey" font-size="10px" x="166" y="117">
              {{ $t("painPerson.back") }}
            </text>
            <path
              v-for="([name, path], index) in regionsToPlot"
              :key="index"
              :d="path"
              :id="name"
              @mouseover="onHover(name)"
              @mouseleave="onLeave(name)"
              @click="onClick(name)"
              @touchstart="touchEvent = true"
              :fill="
                analyticsMode ? getAnalyticsPainColor(name) : regionColour[name]
              "
              class="body-area"
              :style="disabled || isAreaDisabled(name) ? '' : 'cursor: pointer'"
            />
          </svg>
        </template>
        <span v-html="bodyPartToolTip"></span>
      </v-tooltip>
    </v-row>
    <v-row justify="center" v-if="!disabled && !hideChips">
      <v-chip
        v-for="area in selectedAreas"
        :key="area"
        close
        class="text-body-1 ma-2"
        outlined
        append-icon="close"
        close-icon="close"
        @click:close="confirmRemovePainArea(area)"
        >{{ $t(svgRegions[area].name) }}</v-chip
      >
    </v-row>
    <v-row
      justify="center"
      v-if="!disabled && !hideChips && showWhyWeAskButton"
    >
      <v-btn
        color="green"
        outlined
        rounded
        x-small
        @click="openWhyWeAskDialog()"
        class="mt-2 text-none"
      >
        {{ $t("buttons.whyWeAsk") }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import PainCircleImages from "@/assets/json/DeskAssessment/PainCircleImages.json";
import NotificationDialog from "./NotificationDialog.vue";
import svgRegionsMale from "./PainAreasMale.json";
import svgRegionsFemale from "./PainAreasFemale.json";
import PainPersonQuestionDialog from "./PainPersonQuestionDialog.vue";

export default {
  name: "PainPerson",
  props: {
    value: Object,
    validateForm: {
      type: Function,
      default: () => {
        return true;
      }
    },
    disabled: Boolean,
    linkedToQuestions: Boolean,
    oneAreaOnly: {
      type: Boolean,
      default: false
    },
    assessmentType: String,
    analyticsPainAreas: Array,
    selectedColor: String,
    hideChips: Boolean,
    hideGenderChange: Boolean
  },
  components: {
    NotificationDialog,
    PainPersonQuestionDialog
  },
  data() {
    return {
      currentSelection: null,
      currentHoverArea: null,
      painIndicatorImage: require("@/assets/images/painindicator/basemap.png"),
      discardDialog: false,
      finishQuestionsDialog: false,
      areaToBeRemoved: null,
      touchEvent: false,
      showPainQuestionPurposeDialog: false
    };
  },
  computed: {
    showWhyWeAskButton() {
      return this._i18n.locale.includes("en");
    },
    areaToBeRemovedText() {
      return this.areaToBeRemoved
        ? this.$t(this.svgRegions[this.areaToBeRemoved].name).toLowerCase()
        : "";
    },
    areaToRemoveImage() {
      return this.areaToBeRemoved ? PainCircleImages[this.areaToBeRemoved] : "";
    },
    selectedAreas() {
      return Object.keys(this.value.areas);
    },
    gender() {
      return this.value.gender ? this.value.gender : "male";
    },
    genderText() {
      if (this.gender === "male") {
        return this.$t("painPerson.feminine");
      } else {
        return this.$t("painPerson.masculine");
      }
    },
    svgRegions() {
      if (this.gender === "male") {
        return svgRegionsMale;
      } else {
        return svgRegionsFemale;
      }
    },
    regionColour() {
      return Object.fromEntries(
        Object.keys(this.svgRegions).map(areaName => {
          const isSelected = this.selectedAreas.includes(areaName);
          const isHovered = this.currentHoverArea === areaName;
          const isDisabled = this.isAreaDisabled(areaName);

          let fillColour;
          if (isSelected && isHovered) {
            fillColour = "#cc5555";
          } else if (isDisabled) {
            fillColour = "#d8d7d6";
          } else if (isSelected) {
            fillColour = this.selectedColor ?? "#e51b20";
          } else if (isHovered) {
            fillColour = "#969696";
          } else {
            fillColour = "#bebebe";
          }
          return [areaName, fillColour];
        })
      );
    },
    bodyPartToolTip() {
      let area = this.currentHoverArea
        ? this.$t(this.svgRegions[this.currentHoverArea].name)
        : null;
      if (this.analyticsMode) {
        let painArea = this.analyticsPainAreas.find(
          x => x.id === this.currentHoverArea
        );
        let defaultMessage = this.touchEvent
          ? this.$t("tooltips.painBodyClick")
          : this.$t("tooltips.painBodyHover");
        return painArea
          ? area +
              ": " +
              painArea.percentage +
              "%" +
              this.$tc("painPerson.people", painArea.count, {
                n: painArea.count
              })
          : defaultMessage;
      }
      return area ? area : this.$t("tooltips.painChooseBodyPart");
    },
    regionsToPlot() {
      return Object.entries(this.svgRegions).flatMap(([name, obj]) =>
        obj.paths.map(path => [this.$t(name), path])
      );
    },
    analyticsMode() {
      return !!this.analyticsPainAreas && this.analyticsPainAreas.length > 0;
    }
  },
  methods: {
    onHover(areaName) {
      if (
        (this.disabled && !this.analyticsMode) ||
        this.isAreaDisabled(areaName)
      ) {
        return;
      }
      this.currentHoverArea = areaName;
    },
    onLeave(areaName) {
      if (this.currentHoverArea === areaName) {
        this.currentHoverArea = null;
      }
    },
    onClick(value) {
      if (this.disabled || this.isAreaDisabled(value)) {
        return;
      }

      if (
        this.oneAreaOnly &&
        !!this.currentSelection &&
        this.currentSelection !== value
      ) {
        this.removePainArea(this.currentSelection);
      }

      if (this.selectedAreas.includes(value)) {
        this.areaToBeRemoved = value;
        if (this.linkedToQuestions) {
          this.confirmRemovePainArea(value);
        }
      } else if (!this.selectedAreas.includes(value)) {
        let tempAreas = { ...this.value.areas };
        tempAreas[value] = {};
        this.value.areas = tempAreas;
        this.notifyParent(value);
      } else {
        this.notifyParent(value);
      }
    },
    isAreaDisabled(area) {
      if (!this.svgRegions[area].disabled) {
        return false;
      }
      return this.svgRegions[area].disabled[this.assessmentType];
    },
    async confirmRemovePainArea(value) {
      this.areaToBeRemoved = value;
      let levelQuestionAnswered = !!this.value.areas[value].level;
      if (this.linkedToQuestions && levelQuestionAnswered) {
        let confirmed = await this.$refs.discardPainDialog.open();
        if (confirmed) {
          this.removePainArea(this.areaToBeRemoved);
        }
      } else {
        this.removePainArea();
      }
    },
    removePainArea(value) {
      let area = value ?? this.areaToBeRemoved;
      this.$delete(this.value.areas, area);
      this.$emit("removeArea", area);
      if (this.currentSelection === area) {
        this.notifyParent(null);
      }
      this.areaToBeRemoved = null;
    },
    changeDisplayedQuestions(value) {
      this.notifyParent(value);
    },
    notifyParent(value) {
      this.currentSelection = value;
      this.$emit("selectionChanged", value);
      this.$emit(
        "selectionNameChanged",
        value ? this.$t(this.svgRegions[value].name) : ""
      );
    },
    changeGender() {
      let newGender = this.gender === "male" ? "female" : "male";
      this.$emit("genderChanged", newGender);
    },
    getAnalyticsPainColor(name) {
      let region = this.analyticsPainAreas.find(x => x.id === name);
      return region ? region.color : "#d8d7d6";
    },
    openWhyWeAskDialog() {
      this.$mixpanel.track(`Clicked on why we ask for pain person`);
      this.showPainQuestionPurposeDialog = true;
    }
  }
};
</script>

<style scoped>
.body-area {
  stroke: #aaa;
  stroke-width: 0.2;
  stroke-linejoin: round;
}
.disabled-body-area {
  fill: #d8d7d6;
  stroke-width: 0;
  stroke-linejoin: round;
}
</style>
