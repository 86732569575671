var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showDialog
    ? _c(
        "v-dialog",
        {
          attrs: { "max-width": "650" },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "text-center rounded-lg",
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pa-12" : "pa-6"
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: "center",
                    align: "center",
                    "no-gutters": ""
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            align: "center",
                            justify: "center",
                            "no-gutters": ""
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-img",
                                {
                                  class: _vm.outlineImage
                                    ? "rounded-circle"
                                    : "",
                                  attrs: {
                                    id: "image",
                                    contain: "",
                                    height: "110",
                                    src: require("@/assets/images/" + _vm.image)
                                  }
                                },
                                [
                                  _vm.outlineImage
                                    ? _c("div", {
                                        staticClass: "circle-outline"
                                      })
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { staticClass: "my-10", attrs: { cols: "12" } }, [
                    _c(
                      "p",
                      { staticClass: "text-h5 my-0", attrs: { id: "heading" } },
                      [_vm._v(_vm._s(_vm.heading))]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "text-subtitle-1 my-0 vitrueGrey--text",
                        attrs: { id: "subheading" }
                      },
                      [_vm._v(" " + _vm._s(_vm.subheading) + " ")]
                    )
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "declineButton",
                                    width: "100%",
                                    color: "primary",
                                    rounded: "",
                                    ripple: "",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.cancel()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.declineText))]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "confirmButton",
                                    width: "100%",
                                    color: "primary",
                                    rounded: "",
                                    ripple: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.agree()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.confirmText))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }