var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-12" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("v-img", {
            attrs: {
              height: "200px",
              contain: "",
              src: require("@/assets/images/undraw_notify.svg")
            }
          }),
          _c("v-card-title", { staticClass: "mt-5 mb-2" }, [
            _vm._v(_vm._s(_vm.heading))
          ]),
          _c("v-card-subtitle", [_vm._v(_vm._s(_vm.subheading))]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-5 mx-5",
                  attrs: { color: "primary", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("discard")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.discard")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "px-5 mx-5",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("keep")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.keep")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }